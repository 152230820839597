import { createSlice } from "@reduxjs/toolkit";
import { setHeaders, removeHeader, makeRequest } from "../../services/api";
import { log } from "../../services/log";
import { addMessage } from "../messages/messagesSlice";
import Cookies from "universal-cookie";
import history from "../history";

const initialState = {
  isRequesting: false,
  isLoggedIn: false,
  accessToken: null
};

const cookies = new Cookies();
const cookieKey = "dminr-auth-refresh-token";
const cookieSettings = {
  path: "/",
  sameSite: "strict",
  maxAge: 60 * 60 * 24 * 30 // 30 days
};

export const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    authRequest: state => {
      state.accessToken = null;
      state.isLoggedIn = false;
      state.isRequesting = true;
    },
    authSuccess: (state, action) => {
      if (state.isRequesting) {
        const { access } = action.payload;
        state.accessToken = access;
        state.isLoggedIn = true;
        state.isRequesting = false;
      }
    },
    authFailure: state => {
      state.accessToken = null;
      state.isLoggedIn = false;
      state.isRequesting = false;
    },
    authReset: state => {
      state.accessToken = null;
      state.isLoggedIn = false;
      state.isRequesting = false;
    }
  }
});

// expose actions
export const {
  setLoggedIn,
  authRequest,
  authSuccess,
  authFailure,
  authReset
} = slice.actions;

export const removeToken = () => async dispatch => {
  dispatch(authReset());
  removeHeader("authorization");
  cookies.remove(cookieKey, cookieSettings);
  history.push("/");
};

// login thunk
export const requestToken = data => async dispatch => {
  const apiUrl = `/token/`;
  const options = {
    method: "POST",
    body: JSON.stringify(data)
  };
  dispatch(makeTokenRequest(apiUrl, options));
};

export const refreshToken = () => async dispatch => {
  const refresh = cookies.get(cookieKey);
  if (!refresh) return;
  const data = {
    refresh
  };
  const apiUrl = `/token/refresh/`;
  const options = {
    method: "POST",
    body: JSON.stringify(data)
  };
  dispatch(makeTokenRequest(apiUrl, options));
};

export const makeTokenRequest = (apiUrl, options) => async dispatch => {
  dispatch(authRequest());
  try {
    const token = await makeRequest(apiUrl, options);
    const { access, refresh } = token;
    setHeaders({ authorization: `Bearer ${access}` });
    cookies.set(cookieKey, refresh, cookieSettings);
    dispatch(authSuccess(token));
    log({ action: "login" });
  } catch (err) {
    dispatch(authFailure());
    dispatch(addMessage({ message: err.toString(), type: "error" }));
    dispatch(removeToken());
  }
};

// selectors

export const selectIsLoggedIn = state => state.auth.isLoggedIn;
export const selectIsRequestingAuth = state => state.auth.isRequesting;

export default slice.reducer;

import { configureStore } from "@reduxjs/toolkit";

import messagesReducer from "./messages/messagesSlice";
import authReducer from "./auth/authSlice";
import searchReducer from "./search/searchSlice";
import connectionsReducer from "./connections/connectionsSlice";
import autocompleteReducer from "./autocomplete/autocompleteSlice";
import conceptsReducer from "./concepts/conceptsSlice";

export default configureStore({
  reducer: {
    messages: messagesReducer,
    auth: authReducer,
    search: searchReducer,
    connections: connectionsReducer,
    autocomplete: autocompleteReducer,
    concepts: conceptsReducer
  }
});

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { useSelector, useDispatch } from "react-redux";

import {
  setPage,
  selectPage,
  selectResults
} from "../../features/search/searchSlice";

const SearchResultsPagination = () => {
  const StyledPagination = withStyles(theme => ({
    ul: {
      justifyContent: "center"
    }
  }))(Pagination);

  const dispatch = useDispatch();
  const page = useSelector(selectPage);
  const results = useSelector(selectResults);
  const resultsPerPage = 10;

  const handleChange = (event, value) => {
    dispatch(setPage(value));
  };

  let itemCount = 0;

  return (
    <>
      {results && results.pagination && (
        <StyledPagination
          count={Math.ceil(results.pagination.results / resultsPerPage)}
          page={page || 1}
          onChange={handleChange}
          variant="outlined"
          shape="rounded"
          // hack to remove link to final page of results, as the API doesn't
          // easily enable it to be hidden
          renderItem={item => {
            if (++itemCount === 8) return;
            return <PaginationItem {...item} />;
          }}
        />
      )}
    </>
  );
};

export default SearchResultsPagination;

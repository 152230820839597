import { createSlice } from "@reduxjs/toolkit";
import sub from "date-fns/sub";
import format from "date-fns/format";

import history from "../history";
import { makeRequest } from "../../services/api";
import { log } from "../../services/log";
import { addMessage } from "../messages/messagesSlice";
import {
  addConnections,
  resetConnections
} from "../connections/connectionsSlice";

// search tab labels and their respective data urls
export const searchTypes = [
  {
    label: "News articles",
    value: "news"
  },
  {
    label: "Public sources",
    value: "public"
  },
  {
    label: "Google search",
    value: "google"
  },
  {
    label: "Explore connections",
    value: "connections"
  }
];

export const newsArticlesDataSources = [
  {
    label: "INJECT",
    value: "inject"
  }
];

export const publicSourcesDataSources = [
  {
    label: "Companies House",
    value: "companies-house"
  },
  {
    label: "Open Corporates",
    value: "open-corporates"
  }
];

export const publicSourcesCategories = [
  {
    label: "Companies",
    value: "companies"
  },
  {
    label: "Officers",
    value: "officers"
  }
];

const dateOptions = [
  {
    label: "In the last day",
    filter: { days: 1 }
  },
  {
    label: "In the last week",
    filter: { weeks: 1 }
  },
  {
    label: "In the last month",
    filter: { months: 1 }
  },
  {
    label: "In the last 3 months",
    filter: { months: 3 }
  },
  {
    label: "In the last 6 months",
    filter: { months: 6 }
  },
  {
    label: "In the last year",
    filter: { years: 1 }
  },
  {
    label: "In the last 2 years",
    filter: { years: 2 }
  },
  {
    label: "In the last 5 years",
    filter: { years: 5 }
  }
];

// convert date option filters (above) to a string value with format yyyy-MM-dd
export const getDateOptions = () =>
  dateOptions.map(dateOption => ({
    label: dateOption.label,
    value: format(sub(new Date(), dateOption.filter), "yyyy-MM-dd")
  }));

const initialState = {
  query: "",
  searchType: searchTypes[0].value,
  page: 1,
  sort: "",
  dataSource: "",
  category: "",
  startDate: "",
  endDate: "",
  isRequesting: false,
  results: []
};

export const slice = createSlice({
  name: "search",
  initialState,
  reducers: {
    // query actions
    setQueryValue: (state, action) => {
      state.query = action.payload;
    },
    setSearchTypeValue: (state, action) => {
      const searchTypeValues = searchTypes.map(searchType => searchType.value);
      if (searchTypeValues.includes(action.payload)) {
        state.searchType = action.payload;
      }
    },
    resetResultsValue: state => {
      state.results = initialState.results;
    },
    setPageValue: (state, action) => {
      state.page = parseInt(action.payload, 10);
    },
    resetPageValue: state => {
      state.page = initialState.page;
    },
    setSortValue: (state, action) => {
      state.sort = action.payload;
    },
    resetSortValue: state => {
      state.sort = initialState.sort;
    },
    setDataSourceValue: (state, action) => {
      state.dataSource = action.payload;
    },
    resetDataSourceValue: state => {
      state.dataSource = initialState.dataSource;
    },
    setStartDateValue: (state, action) => {
      state.startDate = action.payload;
    },
    resetStartDateValue: state => {
      state.startDate = initialState.startDate;
    },
    setEndDateValue: (state, action) => {
      state.endDate = action.payload;
    },
    resetEndDateValue: state => {
      state.endDate = initialState.endDate;
    },
    setCategoryValue: (state, action) => {
      state.category = action.payload;
    },
    resetCategoryValue: (state, action) => {
      if (action.payload === "public") {
        state.category = publicSourcesCategories[0].value;
      } else {
        state.category = initialState.category;
      }
    },

    // reset entire search state action
    resetSearch: state => initialState,

    // search request (thunks) actions
    searchRequest: state => {
      state.isRequesting = true;
      state.results = [];
    },
    searchSuccess: (state, action) => {
      state.isRequesting = false;
      state.results = action.payload;
    },
    searchFailure: state => {
      state.isRequesting = false;
      state.results = [];
    },
    searchComplete: state => {
      state.isRequesting = false;
    }
  }
});

// expose actions
export const {
  setQueryValue,
  resetResultsValue,
  setSearchTypeValue,
  setPageValue,
  resetPageValue,
  setSortValue,
  resetSortValue,
  setDataSourceValue,
  resetDataSourceValue,
  setCategoryValue,
  resetCategoryValue,
  setStartDateValue,
  resetStartDateValue,
  setEndDateValue,
  resetEndDateValue,
  resetSearch,
  searchRequest,
  searchSuccess,
  searchFailure,
  searchComplete
} = slice.actions;

// thunks to update search values and then update query URL
export const setQuery = action => dispatch => {
  dispatch(setQueryValue(action));
  dispatch(resetConnections());
  dispatch(resetPageValue());
  dispatch(resetSortValue());
  dispatch(resetDataSourceValue());
  dispatch(resetStartDateValue());
  dispatch(resetEndDateValue());
  dispatch(updateSearchUrl());
};
export const setSearchType = action => dispatch => {
  dispatch(setSearchTypeValue(action));
  dispatch(resetResultsValue());
  dispatch(resetConnections());
  dispatch(resetPageValue());
  dispatch(resetSortValue());
  dispatch(resetCategoryValue(action));
  dispatch(resetDataSourceValue());
  dispatch(resetStartDateValue());
  dispatch(resetEndDateValue());
  dispatch(updateSearchUrl());
};
export const setQueryAndSearchType = action => dispatch => {
  const { query, searchType } = action;
  dispatch(setQueryValue(query));
  dispatch(setSearchType(searchType));
};
export const setPage = action => dispatch => {
  dispatch(setPageValue(action));
  dispatch(updateSearchUrl());
};
export const setSort = action => dispatch => {
  dispatch(setSortValue(action));
  dispatch(resetPageValue());
  dispatch(updateSearchUrl());
};
export const setDataSource = action => dispatch => {
  dispatch(setDataSourceValue(action));
  dispatch(resetPageValue());
  dispatch(updateSearchUrl());
};
export const setCategory = action => dispatch => {
  dispatch(resetResultsValue());
  dispatch(setCategoryValue(action));
  dispatch(resetPageValue());
  dispatch(updateSearchUrl());
};
export const setStartDate = action => dispatch => {
  dispatch(setStartDateValue(action));
  dispatch(resetPageValue());
  dispatch(updateSearchUrl());
};
export const setEndDate = action => dispatch => {
  dispatch(setEndDateValue(action));
  dispatch(resetPageValue());
  dispatch(updateSearchUrl());
};
export const resetStartEndDates = action => dispatch => {
  dispatch(resetStartDateValue());
  dispatch(resetEndDateValue());
  dispatch(resetPageValue());
  dispatch(updateSearchUrl());
};
export const setDateFromOptions = action => dispatch => {
  dispatch(resetEndDateValue());
  dispatch(setStartDate(action));
};

// thunk to update the URL query string from the currently stored values
// this is called any time search params are updated
export const updateSearchUrl = () => (dispatch, getState) => {
  const state = getState();
  const apiUrlQuery = calculateApiUrlQuery(state);
  const searchUrlQuery = `/search?${apiUrlQuery}`;
  history.push(searchUrlQuery);
};

// thunk to update stored values from URL query string and query the API
// call all value setters directly, don't trigger URL update
// runs whenever the search page URL updates
export const parseURLParamsAndQuery = paramsString => (dispatch, getState) => {
  let state = getState();

  const params = new URLSearchParams(paramsString);

  // replace all encoded+strings to ' ' spaces
  // (matches the behaviour of browser form submissions)
  const query = params.has("query")
    ? params.get("query").replace(/\+/g, " ")
    : selectQuery(state);
  dispatch(setQueryValue(query));

  const searchType = params.has("type")
    ? params.get("type")
    : selectSearchType(state);
  dispatch(setSearchTypeValue(searchType));

  const page = params.has("page") ? params.get("page") : 1;
  dispatch(setPageValue(page));

  const sort = params.has("sort") ? params.get("sort") : selectSort(state);
  if (sort) {
    dispatch(setSortValue(sort));
  }

  const dataSource = params.has("source")
    ? params.get("source")
    : selectDataSource(state);
  if (dataSource) {
    dispatch(setDataSourceValue(dataSource));
  }

  const category = params.has("category")
    ? params.get("category")
    : selectCategory(state);
  if (category) {
    dispatch(setCategoryValue(category));
  }

  const startDate = params.has("start-date")
    ? params.get("start-date")
    : selectStartDate(state);
  if (startDate) {
    dispatch(setStartDateValue(startDate));
  }

  const endDate = params.has("end-date")
    ? params.get("end-date")
    : selectEndDate(state);
  if (endDate) {
    dispatch(setEndDateValue(endDate));
  }

  // re-fetch the state now it has been updated
  state = getState();

  const apiUrlQuery = calculateApiUrlQuery(state);
  dispatch(searchFetch(apiUrlQuery));
};

// calculate the API URL query string from stored values
const calculateApiUrlQuery = state => {
  const params = new URLSearchParams();

  const query = selectQuery(state);
  params.append("query", query);

  const searchType = selectSearchType(state);
  params.append("type", searchType);

  const page = selectPage(state);
  if (page && page > 1) {
    params.append("page", page);
  }

  const sort = selectSort(state);
  if (sort) {
    params.append("sort", sort);
  }

  const dataSource = selectDataSource(state);
  if (dataSource) {
    params.append("source", dataSource);
  }

  const category = selectCategory(state);
  if (category) {
    params.append("category", category);
  }

  const startDate = selectStartDate(state);
  if (startDate) {
    params.append("start-date", startDate);
  }

  const endDate = selectEndDate(state);
  if (endDate) {
    params.append("end-date", endDate);
  }

  // (match the behaviour of browser form submissions)
  const apiUrlQuery = params
    .toString()
    .replace(/%20/g, "+") // replace all %-encoded spaces to the '+' character
    .replace(/%2C/g, ","); // replace all %-encoded ',' to the ',' character

  return apiUrlQuery;
};

// search thunk
export const searchFetch = apiUrlQuery => async (dispatch, getState) => {
  const state = getState();
  const query = selectQuery(state);
  const searchType = selectSearchType(state);

  if (query.trim().length === 0) return;

  const apiUrl = `/search/${searchType}?${apiUrlQuery}`;

  dispatch(searchRequest());

  try {
    const results = await makeRequest(apiUrl);

    // handle connections in a separate slice
    if (searchType === "connections") {
      dispatch(addConnections({ query, results: results.results }));
      dispatch(searchComplete());
    } else {
      // handle all other API request types
      dispatch(searchSuccess(results));
    }
  } catch (err) {
    dispatch(searchFailure());
    dispatch(addMessage({ message: err.toString(), type: "error" }));
    log({
      action: "error",
      primaryDetail: err.toString(),
      fullDetail: apiUrl
    });
  }
};

// selectors

export const selectQuery = state => state.search.query;
export const selectPage = state => state.search.page;
export const selectSort = state => state.search.sort;
export const selectDataSource = state => state.search.dataSource;
export const selectCategory = state => state.search.category;
export const selectStartDate = state => state.search.startDate;
export const selectEndDate = state => state.search.endDate;
export const selectResults = state => state.search.results;
export const selectSearchType = state => state.search.searchType;
export const selectIsRequestingResults = state => state.search.isRequesting;

export default slice.reducer;

import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import format from "date-fns/format";

import EmptyResults from "../EmptyResults/EmptyResults";
import ChipWithPopper from "../ChipWithPopper/ChipWithPopper";

import { log } from "../../services/log";
import {
  selectResults,
  selectIsRequestingResults
} from "../../features/search/searchSlice";

const useStyles = makeStyles(theme => ({
  loading: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(3)
  },
  mainLink: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.primary.dark
    }
  },
  urlLink: {
    color: "inherit",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.primary.main
    }
  }
}));

const StyledCardActions = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey.xlight,
    color: theme.palette.typography.text,
    padding: "8px 16px"
  }
}))(CardActions);

const SearchResultsListNewsArticles = () => {
  const results = useSelector(selectResults);
  const isLoadingResults = useSelector(selectIsRequestingResults);

  const classes = useStyles();

  const { pathname, search } = useLocation();

  const handleLinkClick = async e => {
    e.preventDefault();
    const target = e.currentTarget.href;
    await log({
      action: "click",
      primaryDetail: target,
      fullDetail: `${pathname}${search}`
    });
    window.location.href = target;
  };

  return (
    <>
      {isLoadingResults && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
      {!isLoadingResults && (!results || !results.results) && <EmptyResults />}
      {results &&
        results.results &&
        results.results.map(result => (
          <Box key={result.id} mt={2}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {result.title && (
                    <>
                      {result.url ? (
                        <Link
                          onClick={e => handleLinkClick(e)}
                          className={classes.mainLink}
                          href={result.url}
                        >
                          {result.title}
                        </Link>
                      ) : (
                        result.title
                      )}
                    </>
                  )}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {result.publisher} •{" "}
                  {result.publication_date && (
                    <>
                      {format(new Date(result.publication_date), "d MMMM yyyy")}
                      {" • "}
                      Data source: {result.source}
                    </>
                  )}
                </Typography>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                  {result.snippet}
                </Typography>
              </CardContent>
              <Divider />
              <StyledCardActions>
                {result.concepts && result.concepts.length > 0 && (
                  <>
                    <Typography variant="body2" color="textSecondary">
                      Related:
                    </Typography>
                    {result.concepts.map((concept, index) => (
                      <ChipWithPopper
                        key={index}
                        id={index}
                        title={concept.label}
                      />
                    ))}
                  </>
                )}
              </StyledCardActions>
            </Card>
          </Box>
        ))}
    </>
  );
};

export default SearchResultsListNewsArticles;

import React from "react";
import { useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ScatterPlotIcon from "@material-ui/icons/ScatterPlot";

import SearchBox from "../SearchBox/SearchBox";
import LogInForm from "../LogInForm/LogInForm";

import { selectIsLoggedIn } from "../../features/auth/authSlice";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center"
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  tagline: {
    textAlign: "center"
  }
}));

const StyledTypography = withStyles(theme => ({
  root: {
    marginLeft: 2
  }
}))(Typography);

const Home = props => {
  const classes = useStyles();

  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <>
      <main className={classes.root}>
        <Container maxWidth="lg">
          <Grid container justify="center" alignItems="center" spacing={0}>
            <Grid item xs={12}>
              <div className={classes.logo}>
                <ScatterPlotIcon fontSize="large" />
                <StyledTypography variant="h2">DMINR</StyledTypography>
              </div>
            </Grid>
            <Grid item sm={8}>
              <div className={classes.tagline}>
                <Typography paragraph variant="body1">
                  Blending journalistic expertise and AI to combat fake news
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              {isLoggedIn ? <SearchBox /> : <LogInForm />}
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  );
};

export default Home;

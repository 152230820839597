import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ScatterPlotIcon from "@material-ui/icons/ScatterPlot";

import { selectIsLoggedIn } from "../../features/auth/authSlice";
import { addMessage } from "../../features/messages/messagesSlice";

import { removeToken } from "../../features/auth/authSlice";

const StyledAppBar = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.dark
  }
}))(AppBar);

const StyledLink = withStyles(theme => ({
  root: {
    textDecoration: "none !important",
    fontWeight: "normal !important"
  }
}))(Link);

const StyledTypography = withStyles(theme => ({
  root: {
    marginLeft: 4
  }
}))(Typography);

const useStyles = makeStyles(theme => ({
  middle: {
    flexGrow: 1
  },
  link: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.typography.white,
    textDecoration: "underline",
    textTransform: "uppercase",
    fontWeight: "bold",
    "&:hover": {
      color: theme.palette.grey.xlight
    }
  }
}));

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const handleLogOut = e => {
    e.preventDefault();
    dispatch(removeToken());
    dispatch(addMessage({ message: "You have been logged out" }));
  };

  return (
    <>
      <StyledAppBar position="static">
        <Toolbar>
          <StyledLink className={classes.link} component={RouterLink} to="/">
            <ScatterPlotIcon />
            <StyledTypography variant="h5">DMINR</StyledTypography>
          </StyledLink>
          <div className={classes.middle} />
          {isLoggedIn ? (
            <Button
              className={classes.link}
              color="secondary"
              size="small"
              onClick={handleLogOut}
            >
              Log out
            </Button>
          ) : (
            <Link className={classes.link} component={RouterLink} to={"/"}>
              Log In
            </Link>
          )}
        </Toolbar>
      </StyledAppBar>
    </>
  );
};

export default Header;

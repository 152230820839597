import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messages: []
};

export const slice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    resetMessages: state => initialState,
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    removeMessage: state => {
      state.messages.pop();
    }
  }
});

// expose actions

export const { resetMessages, addMessage, removeMessage } = slice.actions;

// selectors

export const selectMessages = state => state.messages.messages;
export const selectLatestMessage = state =>
  state.messages.messages.length > 0
    ? state.messages.messages[state.messages.messages.length - 1]
    : null;

export default slice.reducer;

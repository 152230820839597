import { createSelector, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../services/api";
import { log } from "../../services/log";
import { addMessage } from "../messages/messagesSlice";

const initialState = {
  isRequesting: false,
  activeConcept: null,
  concepts: {}
};

export const slice = createSlice({
  name: "concepts",
  initialState,
  reducers: {
    setActiveConcept: (state, action) => {
      state.activeConcept = action.payload;
    },
    conceptRequest: state => {
      state.isRequesting = true;
    },
    conceptSuccess: (state, action) => {
      if (state.isRequesting) {
        state.isRequesting = false;
        const { concept, results } = action.payload;
        state.concepts[concept] = results;
      }
    },
    conceptFailure: state => {
      state.isRequesting = false;
    }
  }
});

// expose actions
export const {
  setActiveConcept,
  conceptRequest,
  conceptSuccess,
  conceptFailure
} = slice.actions;

// autocomlete thunk
export const fetchConcept = () => async (dispatch, getState) => {
  const state = getState();
  const concept = selectActiveConcept(state);
  const concepts = selectConcepts(state);

  // if no concept selected
  if (!concept) return;

  // if we've already cached this result
  if (concepts[concept]) {
    return;
  }

  const encodedConcept = concept.replace(/ /g, "+");

  const apiUrl = `/search/news/concepts?concept=${encodedConcept}`;

  dispatch(conceptRequest());
  try {
    const results = await makeRequest(apiUrl);
    dispatch(conceptSuccess({ concept, results }));
  } catch (err) {
    dispatch(conceptFailure());
    dispatch(addMessage({ message: err.toString(), type: "error" }));
    log({
      action: "error",
      primaryDetail: err.toString(),
      fullDetail: apiUrl
    });
  }
};

// selectors

export const selectConcepts = state => state.concepts.concepts;
export const selectActiveConcept = state => state.concepts.activeConcept;
export const selectInformationForActiveConcept = createSelector(
  [selectConcepts, selectActiveConcept],
  (concepts, activeConcept) => {
    if (!activeConcept) return;
    if (!concepts[activeConcept]) return;
    return concepts[activeConcept];
  }
);
export const selectIsRequestingConcepts = state => state.concepts.isRequesting;

export default slice.reducer;

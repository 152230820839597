import React from "react";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import {
  setQuery,
  setQueryAndSearchType,
  selectIsRequestingResults
} from "../../features/search/searchSlice";
import {
  selectQuery,
  selectFormattedConnectionData
} from "../../features/connections/connectionsSlice";

import EmptyResults from "../EmptyResults/EmptyResults";
import ConnectionsVisualisation from "./ConnectionsVisualisation";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative"
  },
  loading: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(3),
    position: "absolute",
    left: 0,
    right: 0,
    top: 0
  },
  visualisation: {}
}));

const Connections = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const query = useSelector(selectQuery);
  const connections = useSelector(selectFormattedConnectionData);
  const isLoadingResults = useSelector(selectIsRequestingResults);

  const [show, setShow] = React.useState(false);

  const handleExploreConnections = value => {
    if (value && value.trim().length > 0) {
      dispatch(setQuery(value.trim()));
    }
  };

  const handleBrowseArticles = value => {
    if (value && value.trim().length > 0) {
      dispatch(
        setQueryAndSearchType({
          query: `${query} ${value.trim()}`,
          searchType: "news"
        })
      );
    }
  };

  // reveal visualisation on data load
  React.useEffect(() => {
    setShow(!isLoadingResults);
    return () => {
      setShow(false);
    };
  }, [isLoadingResults]);

  return (
    <main className={classes.root}>
      {!show && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
      {connections.children.length > 0 ? (
        <Collapse in={show} timeout={1000}>
          <div className={classes.visualisation}>
            <ConnectionsVisualisation
              query={query}
              connections={connections}
              handleExploreConnections={handleExploreConnections}
              handleBrowseArticles={handleBrowseArticles}
            />
          </div>
        </Collapse>
      ) : (
        <>
          {!isLoadingResults && (
            <Box m={2}>
              <EmptyResults />
            </Box>
          )}
        </>
      )}
    </main>
  );
};

export default Connections;

import { makeRequest } from "./api";

export async function log({ action, primaryDetail = "", fullDetail = "" }) {
  const params = new URLSearchParams();

  params.append("action", action);
  params.append("primary_detail", primaryDetail);
  params.append("full_detail", fullDetail);

  const apiUrlQuery = params
    .toString()
    .replace(/%20/g, "+") // replace all %-encoded spaces to the '+' character
    .replace(/%2C/g, ","); // replace all %-encoded ',' to the ',' character

  const apiUrl = `/log?${apiUrlQuery}`;

  try {
    await makeRequest(apiUrl);
  } catch (err) {
    const isDebugMode = process.env.NODE_ENV === "development";
    if (isDebugMode) {
      console.log("LOG ERROR", err);
    }
  }

  return true;
}

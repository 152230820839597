import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ScatterPlotIcon from "@material-ui/icons/ScatterPlot";
import Typography from "@material-ui/core/Typography";
import SearchResultsNewsArticles from "../SearchResultsNewsArticles/SearchResultsNewsArticles";
import SearchResultsGoogleSearch from "../SearchResultsGoogleSearch/SearchResultsGoogleSearch";
import SearchResultsPublicSources from "../SearchResultsPublicSources/SearchResultsPublicSources";
import Connections from "../Connections/Connections";
import { useSelector, useDispatch } from "react-redux";

import {
  searchTypes,
  setSearchType,
  selectSearchType
} from "../../features/search/searchSlice";

const StyledAppBar = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    boxShadow: "none",
    position: "relative"
  }
}))(AppBar);

const StyledTab = withStyles(theme => ({
  root: {
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  selected: {
    background: theme.palette.primary.xlight,
    pointerEvents: "none"
  }
}))(Tab);

const useStyles = makeStyles(theme => ({
  connectionsTab: {
    background: theme.palette.secondary.light,
    marginLeft: "auto",
    "&:hover": {
      background: theme.palette.secondary.main
    },
    "&.Mui-selected": {
      background: theme.palette.secondary.main,
      pointerEvents: "none"
    }
  },
  connectionsTabContent: {
    display: "flex",
    alignItems: "center"
  },
  connectionsTabIcon: {
    fill: theme.palette.typography.white,
    fontSize: 20,
    marginRight: 2
  },
  connectionsTabLabel: {
    color: theme.palette.typography.white
  }
}));

function TabPanel(props) {
  const { children, index, value, ...other } = props;
  return (
    <div hidden={index !== value} {...other}>
      {index === value && children}
    </div>
  );
}

const SearchResults = () => {
  const dispatch = useDispatch();

  const selectedSearchType = useSelector(selectSearchType);

  const selectedTabIndex = searchTypes.reduce(
    (currentValue, searchType, index) => {
      return searchType.value === selectedSearchType ? index : currentValue;
    },
    0
  );

  const [value, setValue] = React.useState(selectedTabIndex);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setSearchType(searchTypes[newValue].value));
  };

  // update search value on load
  React.useEffect(() => {
    setValue(selectedTabIndex);
  }, [selectedTabIndex]);

  const classes = useStyles();

  return (
    <>
      <StyledAppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {searchTypes.map(searchType =>
            searchType.value === "connections" ? (
              <StyledTab
                key={searchType.value}
                className={classes.connectionsTab}
                label={
                  <div className={classes.connectionsTabContent}>
                    <ScatterPlotIcon className={classes.connectionsTabIcon} />
                    <Typography
                      className={classes.connectionsTabLabel}
                      variant="body2"
                    >
                      {searchType.label}
                    </Typography>
                  </div>
                }
              />
            ) : (
              <StyledTab key={searchType.value} label={searchType.label} />
            )
          )}
        </Tabs>
      </StyledAppBar>
      <TabPanel value={value} index={0}>
        <SearchResultsNewsArticles />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SearchResultsPublicSources />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SearchResultsGoogleSearch />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Connections />
      </TabPanel>
    </>
  );
};

export default SearchResults;

import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import EmptyResults from "../EmptyResults/EmptyResults";

import { log } from "../../services/log";
import {
  selectResults,
  selectIsRequestingResults
} from "../../features/search/searchSlice";

const useStyles = makeStyles(theme => ({
  loading: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(3)
  },
  mainLink: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.primary.dark
    }
  },
  urlLink: {
    color: "inherit",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.primary.main
    }
  }
}));

const SearchResultsListGoogleSearch = () => {
  const results = useSelector(selectResults);
  const isLoadingResults = useSelector(selectIsRequestingResults);

  const classes = useStyles();

  const { pathname, search } = useLocation();

  const handleLinkClick = async e => {
    e.preventDefault();
    const target = e.currentTarget.href;
    await log({
      action: "click",
      primaryDetail: target,
      fullDetail: `${pathname}${search}`
    });
    window.location.href = target;
  };

  return (
    <>
      {isLoadingResults && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
      {!isLoadingResults && (!results || !results.results) && <EmptyResults />}
      {results &&
        results.results &&
        results.results.map(result => (
          <Box key={result.id} mt={2}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {result.title && (
                    <Link
                      className={classes.mainLink}
                      href={result.url}
                      onClick={e => handleLinkClick(e)}
                    >
                      {result.title}
                    </Link>
                  )}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {result.url && (
                    <Link
                      className={classes.urlLink}
                      href={result.url}
                      onClick={e => handleLinkClick(e)}
                    >
                      {result.site_title}
                    </Link>
                  )}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {result.snippet}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        ))}
    </>
  );
};

export default SearchResultsListGoogleSearch;

import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useSelector, useDispatch } from "react-redux";

import {
  removeMessage,
  selectLatestMessage
} from "../../features/messages/messagesSlice";

const Messages = () => {
  const dispatch = useDispatch();
  const message = useSelector(selectLatestMessage);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(removeMessage());
  };

  return (
    <>
      <Snackbar
        open={!!message}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {message && (
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity={message.type}
          >
            {message.message}
          </MuiAlert>
        )}
      </Snackbar>
    </>
  );
};

export default Messages;

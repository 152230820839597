import React, { useEffect, useRef, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useCookies } from "react-cookie";
import Button from "@material-ui/core/Button";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CloseIcon from "@material-ui/icons/Close";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { drag } from "d3-drag";
import {
  forceSimulation,
  forceManyBody,
  forceLink,
  forceX,
  forceY
} from "d3-force";
import { hierarchy } from "d3-hierarchy";
import { scalePow } from "d3-scale";
import { mouse, select, selectAll, event as currentEvent } from "d3-selection";
import { symbol, symbolSquare, symbolCircle } from "d3-shape";
import { transition } from "d3-transition";
import { zoom, zoomIdentity, zoomTransform } from "d3-zoom";

const StyledButton = withStyles(theme => ({
  root: {
    color: theme.palette.typography.white,
    background: theme.palette.primary.main,
    margin: theme.spacing(0.5),
    "&:hover": {
      background: theme.palette.primary.dark
    }
  }
}))(Button);

const useStyles = makeStyles(theme => ({
  // elements

  visualisation: {
    cursor: "grab",
    color: theme.palette.typography.text,
    position: "relative"
  },
  svg: {
    backgroundColor: theme.palette.grey.xlight
  },

  // panels

  panel: {
    position: "absolute",
    padding: theme.spacing(1, 2),
    background: "rgba(0,0,0,0.7)",
    backdropFilter: "blur(2px)",
    color: theme.palette.typography.white
  },

  panelTitle: {
    fontSize: 20,
    margin: theme.spacing(0, 0, 1, 0),
    textTransform: "uppercase"
  },

  panelText: {
    fontSize: 16
  },

  panelIcon: {
    fontSize: 20
  },

  panelSearchTerm: {
    display: "inline-block",
    padding: theme.spacing(0, 0.5)
  },

  panelClose: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: theme.spacing(1),
    cursor: "pointer",
    background: "transparent",
    border: 0,
    appearance: "none",
    color: theme.palette.typography.white,

    "&:hover": {
      color: theme.palette.grey.xlight
    }
  },

  // panel: key

  panelKey: {
    top: 0,
    left: 0,
    paddingRight: theme.spacing(6)
  },

  panelKeyList: {
    padding: 0,
    margin: 0,
    listStyle: "none"
  },

  panelKeyItem: {
    fontWeight: "bold",
    padding: theme.spacing(0.5, 0),

    "&::before": {
      content: "''",
      display: "inline-block",
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginRight: theme.spacing(1),
      borderRadius: "50%",
      border: "1px solid #fff",
      verticalAlign: "middle"
    }
  },

  panelKeyItemPerson: {
    "&::before": {
      background: theme.palette.entities.person
    }
  },
  panelKeyItemOrganisation: {
    "&::before": {
      background: theme.palette.entities.organisation
    }
  },
  panelKeyItemPlace: {
    "&::before": {
      background: theme.palette.entities.place
    }
  },
  panelKeyItemThing: {
    "&::before": {
      background: theme.palette.entities.thing
    }
  },

  panelAboutToggle: {
    position: "absolute",
    top: 0,
    right: 0,
    padding: theme.spacing(1),
    background: "transparent",
    cursor: "pointer",
    border: 0,
    appearance: "none",
    color: theme.palette.typography.white,

    "&:hover": {
      color: theme.palette.grey.xlight
    }
  },

  // panel: controls

  panelControls: {
    top: 0,
    right: 0
  },

  panelControlsRow: {
    position: "relative",
    textAlign: "center"
  },

  panelControlsReset: {
    display: "inline-block",
    marginLeft: theme.spacing(0.5)
  },

  // panel: about

  panelAbout: {
    top: "25%",
    left: "25%",
    width: "50%",
    padding: theme.spacing(2),
    textAlign: "center"
  },

  // panel: node info

  panelInfo: {
    bottom: 0,
    left: 0,
    right: 0
  },

  // -------------
  // VISUALISATION
  // -------------

  // lines
  line: {
    strokeWidth: 3,
    stroke: theme.palette.grey.light
  },

  // nodes
  nodeWrap: {
    overflow: "visible"
  },
  node: {
    strokeWidth: 1.5,
    stroke: theme.palette.grey.light
  },

  // node types
  nodeQuery: {
    fill: theme.palette.secondary.main
  },
  nodeResult: {
    cursor: "pointer"
  },
  nodeThing: {
    fill: theme.palette.entities.thing
  },
  nodePerson: {
    fill: theme.palette.entities.person
  },
  nodePlace: {
    fill: theme.palette.entities.place
  },
  nodeOrganisation: {
    fill: theme.palette.entities.organisation
  },
  nodeSelected: {
    stroke: theme.palette.grey.dark
  },

  // text
  text: {
    dominantBaseline: "middle",
    fontFamily: "sans-serif",
    fontSize: "12px",
    pointerEvents: "none"
  },

  textQuery: {
    fontWeight: "bold",
    fontSize: "14px"
  }
}));

const ConnectionsVisualisation = props => {
  const {
    query,
    connections,
    handleExploreConnections,
    handleBrowseArticles
  } = props;

  const classes = useStyles();

  const svgRef = useRef(null);
  const visualisationRef = useRef(null);

  // Use both ref and state for monitoring SVG element width:
  // - ref is used in useEffect to reference the current width, without
  //   triggering the effect to run again when the width is updated
  // - state is used for the <svg /> element width and viewbox attributes, and
  //   so uses state to update rendering automatically when it changes
  const [width, setWidth] = useState(800);
  const widthRef = useRef(800);
  const height = 500;

  const resizeObserver = new ResizeObserver(entries => {
    for (const entry of entries) {
      widthRef.current = entry.contentRect.width;
      setWidth(entry.contentRect.width);
    }
  });

  // store 'about' panel visibility in a cookie so we don't show it every time
  const [cookies, setCookie, removeCookie] = useCookies([
    "aboutPanelVisibility"
  ]);
  const [aboutPanelVisibility, setAboutPanelVisibility] = useState(
    cookies["aboutPanelVisibility"] !== "hide"
  );
  const cookieSettings = {
    path: "/",
    sameSite: "strict",
    maxAge: 60 * 60 * 24 * 30 // 30 days
  };

  const [selectedNode, setSelectedNode] = useState(null);

  // methods declared once
  // set up references to allow methods to be declared without having to be
  // passed as references to the useEffect() calls
  const tick = useRef();
  const setNodePath = useRef();
  const setNodeText = useRef();
  const handleDragStarted = useRef();
  const handleDragged = useRef();
  const handleDragEnded = useRef();
  const handleZoom = useRef();
  const handleResultClick = useRef();
  const resetView = useRef();

  // D3 params
  // set up references to allow values to be updated without triggering
  // updates from useEffect() calls
  let svg = useRef();
  let nodeData = useRef();
  let linkData = useRef();
  let nodesGroup = useRef();
  let linksGroup = useRef();
  let nodes = useRef();
  let links = useRef();
  let simulation = useRef();
  let zoomBehaviour = useRef();

  // run D3 setup once, only on init
  useEffect(() => {
    const sizeScale = scalePow()
      .exponent(1)
      .domain([1, 100])
      .range([8, 48]);

    svg.current = select(svgRef.current);

    tick.current = () => {
      nodes.current.attr("x", d => d.x).attr("y", d => d.y);
      links.current
        .attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);
    };

    // allow node text to be set both on node creation and node update
    setNodeText.current = el => {
      el.attr("class", "")
        .classed("text", true)
        .classed("text--query", d => d.data.node === "query")
        .classed(classes.text, true)
        .classed(classes.textQuery, d => d.data.node === "query")
        .attr("dx", d => sizeScale(d.data.count / 2 || 50) + 4)
        .text(d =>
          d.data.concept.length <= 20
            ? d.data.concept
            : `${d.data.concept.substring(0, 20)}…`
        );
    };

    // allow node path to be set both on node creation and node update
    setNodePath.current = el => {
      const calculateNodeSize = d => {
        return Math.PI * Math.pow(sizeScale(d.data.count / 2 || 50), 2);
      };

      el.attr("class", "")
        .classed("node", true)
        .classed(classes.node, true)
        .attr(
          "d",
          symbol()
            .type(d => (d.data.node === "query" ? symbolSquare : symbolCircle))
            .size(calculateNodeSize)
        )
        .classed("node--query", d => d.data.node === "query")
        .classed("node--result", d => d.data.node === "result")
        .classed(classes.nodeQuery, d => d.data.node === "query")
        .classed(classes.nodeResult, d => d.data.node === "result")
        .classed(classes.nodeThing, d => d.data.type === "thing")
        .classed(classes.nodePerson, d => d.data.type === "person")
        .classed(classes.nodePlace, d => d.data.type === "place")
        .classed(classes.nodeOrganisation, d => d.data.type === "organisation");
    };

    handleDragStarted.current = d => {
      if (!currentEvent.active) simulation.current.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    };

    handleDragged.current = d => {
      d.fx = currentEvent.x;
      d.fy = currentEvent.y;
    };

    handleDragEnded.current = d => {
      if (!currentEvent.active) simulation.current.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    };

    handleZoom.current = () => {
      nodesGroup.current.attr("transform", currentEvent.transform);
      linksGroup.current.attr("transform", currentEvent.transform);
    };

    resetView.current = () => {
      svg.current
        .transition()
        .duration(750)
        .call(
          zoomBehaviour.current.transform,
          zoomIdentity,
          zoomTransform(svg.current).invert([widthRef.current / 2, height / 2])
        );
    };

    handleResultClick.current = d => {
      if (d.data.node !== "result") return;

      selectAll(".node--result")
        .classed("is-selected", false)
        .classed(classes.nodeSelected, false);
      select(currentEvent.target)
        .classed(classes.nodeSelected, true)
        .classed("is-selected", true);

      setSelectedNode(d.data);

      // zoom in on selected node
      svg.current
        .transition()
        .duration(750)
        .call(
          zoomBehaviour.current.transform,
          zoomIdentity.scale(1.5).translate(-d.x, -d.y),
          mouse(svg.current.node())
        );
    };

    zoomBehaviour.current = zoom()
      .extent([
        [0, 0],
        [widthRef.current, height]
      ])
      .scaleExtent([0.1, 3])
      .on("zoom", handleZoom.current);

    svg.current.call(zoomBehaviour.current).on("wheel.zoom", null);

    linksGroup.current = svg.current.append("g").classed("lines", true);
    links.current = linksGroup.current.selectAll(".line");

    nodesGroup.current = svg.current.append("g").classed("nodes", true);
    nodes.current = nodesGroup.current.selectAll(".node-wrap");
  }, [classes]);

  // run only on init - watch for resize updates to change SVG width
  useEffect(() => {
    const el = visualisationRef.current;
    resizeObserver.observe(el);
    return () => {
      resizeObserver.unobserve(el);
    };
  }, [resizeObserver]);

  // updates for every time connections data is updated
  useEffect(() => {
    if (connections.children.length === 0) return;

    resetView.current();

    const t = transition().duration(500);
    const fadeIn = node => node.transition(t).attr("opacity", 1.0);
    const fadeOut = node =>
      node
        .transition(t)
        .attr("opacity", 0)
        .remove();

    const root = hierarchy(connections);
    nodeData.current = root.descendants();
    linkData.current = root.links();

    // update lines
    links.current = links.current.data(linkData.current).join(
      // enter
      link =>
        link
          .append("line")
          .classed("line", true)
          .classed(classes.line, true)
          .attr("opacity", 0)
          .call(fadeIn),

      // update
      link => link,

      // remove
      link => link.call(fadeOut)
    );

    // update nodes
    nodes.current = nodes.current.data(nodeData.current).join(
      // enter
      node => {
        const nodeWrap = node
          .append("svg")
          .classed("node-wrap", true)
          .classed(classes.nodeWrap, true)
          .attr("opacity", 0)
          .call(fadeIn)
          .call(
            drag()
              .on("start", handleDragStarted.current)
              .on("drag", handleDragged.current)
              .on("end", handleDragEnded.current)
          )
          .on("click", handleResultClick.current);

        const nodePath = nodeWrap.append("path");
        setNodePath.current(nodePath);

        const nodeText = nodeWrap.append("text");
        setNodeText.current(nodeText);

        return nodeWrap;
      },

      // update
      node => {
        setNodePath.current(node.select(".node"));
        setNodeText.current(node.select(".text"));
        return node;
      },

      // remove
      node => node.call(fadeOut)
    );

    simulation.current = forceSimulation(nodeData.current)
      .force("charge", forceManyBody().strength(-1500))
      .force(
        "link",
        forceLink(linkData.current)
          .distance(200)
          .strength(1)
      )
      .force("x", forceX())
      .force("y", forceY())
      .on("tick", tick.current);
  }, [connections, classes]);

  // button control functions
  const handleZoomInClick = e => {
    svg.current.transition().call(zoomBehaviour.current.scaleBy, 2);
    hideAboutPanel();
  };
  const handleZoomOutClick = e => {
    svg.current.transition().call(zoomBehaviour.current.scaleBy, 0.5);
    hideAboutPanel();
  };
  const handlePanUpClick = e => {
    svg.current.transition().call(zoomBehaviour.current.translateBy, 0, 100);
    hideAboutPanel();
  };
  const handlePanDownClick = e => {
    svg.current.transition().call(zoomBehaviour.current.translateBy, 0, -100);
    hideAboutPanel();
  };
  const handlePanLeftClick = e => {
    svg.current.transition().call(zoomBehaviour.current.translateBy, 100, 0);
    hideAboutPanel();
  };
  const handlePanRightClick = e => {
    svg.current.transition().call(zoomBehaviour.current.translateBy, -100, 0);
    hideAboutPanel();
  };
  const handleResetClick = e => {
    selectAll(".node--result")
      .classed("is-selected", false)
      .classed(classes.nodeSelected, false);
    resetView.current();
    hideAboutPanel();
  };
  const showAboutPanel = e => {
    setAboutPanelVisibility(true);
    removeCookie("aboutPanelVisibility", cookieSettings);
  };
  const hideAboutPanel = e => {
    setAboutPanelVisibility(false);
    setCookie("aboutPanelVisibility", "hide", cookieSettings);
  };
  const handleExploreConnectionsClick = e => {
    hideInfoPanel();
    handleExploreConnections(selectedNode.concept);
  };
  const handleBrowseArticlesClick = e => {
    handleBrowseArticles(selectedNode.concept);
  };
  const hideInfoPanel = e => {
    setSelectedNode(null);
    handleResetClick();
  };

  return (
    <div ref={visualisationRef} className={classes.visualisation}>
      <svg
        className={classes.svg}
        width={width}
        height={height}
        viewBox={[-width / 2, -height / 2, width, height]}
        ref={svgRef}
      />
      <div className={`${classes.panel} ${classes.panelKey}`}>
        <h3 className={classes.panelTitle}>Connections</h3>
        <button className={classes.panelAboutToggle} onClick={showAboutPanel}>
          <HelpOutlineIcon className={classes.panelIcon} />
        </button>
        <ul className={classes.panelKeyList}>
          <li
            className={`${classes.panelKeyItem} ${classes.panelKeyItemPerson}`}
          >
            Person
          </li>
          <li
            className={`${classes.panelKeyItem} ${classes.panelKeyItemOrganisation}`}
          >
            Organisation
          </li>
          <li
            className={`${classes.panelKeyItem} ${classes.panelKeyItemPlace}`}
          >
            Place
          </li>
          <li
            className={`${classes.panelKeyItem} ${classes.panelKeyItemThing}`}
          >
            Thing
          </li>
        </ul>
      </div>
      <div className={`${classes.panel} ${classes.panelControls}`}>
        <div className={classes.panelControlsRow}>
          <StyledButton size="small" onClick={handlePanUpClick}>
            <ArrowUpwardIcon className={classes.panelIcon} />
          </StyledButton>
        </div>
        <div className={classes.panelControlsRow}>
          <StyledButton size="small" onClick={handlePanLeftClick}>
            <ArrowBackIcon className={classes.panelIcon} />
          </StyledButton>
          <StyledButton size="small" onClick={handlePanRightClick}>
            <ArrowForwardIcon className={classes.panelIcon} />
          </StyledButton>
        </div>
        <div className={classes.panelControlsRow}>
          <StyledButton size="small" onClick={handlePanDownClick}>
            <ArrowDownwardIcon className={classes.panelIcon} />
          </StyledButton>
        </div>
        <div className={classes.panelControlsRow}>
          <StyledButton size="small" onClick={handleZoomInClick}>
            <ZoomInIcon className={classes.panelIcon} />
          </StyledButton>
          <StyledButton size="small" onClick={handleZoomOutClick}>
            <ZoomOutIcon className={classes.panelIcon} />
          </StyledButton>
        </div>
        <div className={classes.panelControlsRow}>
          <StyledButton size="small" onClick={handleResetClick}>
            <ZoomOutMapIcon className={classes.panelIcon} />
            <span className={classes.panelControlsReset}>Reset</span>
          </StyledButton>
        </div>
      </div>
      {aboutPanelVisibility && (
        <div className={`${classes.panel} ${classes.panelAbout}`}>
          <h3 className={classes.panelTitle}>Explore Connections</h3>
          <p className={classes.panelText}>
            Use this tool to explore the connections between people,
            organisations, places and things.
          </p>
          <p className={classes.panelText}>
            Select an entity to see relevant news articles containing
            connections between two entities.
          </p>
          <StyledButton size="small" onClick={hideAboutPanel}>
            Start exploring
          </StyledButton>
          <button className={classes.panelClose} onClick={hideAboutPanel}>
            <CloseIcon className={classes.panelIcon} />
          </button>
        </div>
      )}
      {selectedNode && (
        <div className={`${classes.panel} ${classes.panelInfo}`}>
          <h3 className={classes.panelTitle}>{selectedNode.concept}</h3>

          <div>
            <StyledButton size="small" onClick={handleExploreConnectionsClick}>
              Explore connections
            </StyledButton>
            for{" "}
            <strong className={classes.panelSearchTerm}>
              <em> "{selectedNode.concept}" </em>
            </strong>
          </div>

          <div>
            <StyledButton size="small" onClick={handleBrowseArticlesClick}>
              Browse articles
            </StyledButton>
            linking
            <strong className={classes.panelSearchTerm}>
              <em> "{query}" </em>
            </strong>
            <span> and </span>
            <strong className={classes.panelSearchTerm}>
              <em> "{selectedNode.concept}" </em>
            </strong>
          </div>

          <button className={classes.panelClose} onClick={hideInfoPanel}>
            <CloseIcon className={classes.panelIcon} />
          </button>
        </div>
      )}
    </div>
  );
};
export default ConnectionsVisualisation;

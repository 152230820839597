import { createSlice } from "@reduxjs/toolkit";
// import { makeRequest } from "../../services/api";
import { addMessage } from "../messages/messagesSlice";

const initialState = {
  isRequesting: false,
  id: null,
  results: []
};

export const slice = createSlice({
  name: "autocomplete",
  initialState,
  reducers: {
    // reset
    resetAutocomplete: state => initialState,
    clearResults: state => {
      state.results = initialState.results;
    },

    // search request (thunks) actions
    autocompleteRequest: (state, action) => {
      state.isRequesting = true;
      state.id = action.payload.id;
      state.results = [];
    },
    autocompleteSuccess: (state, action) => {
      if (state.isRequesting && state.id === parseFloat(action.payload.id)) {
        state.isRequesting = false;
        state.id = null;
        state.results = action.payload.results;
      }
    },
    autocompleteFailure: state => {
      state.isRequesting = false;
      state.id = null;
      state.results = [];
    }
  }
});

// expose actions
export const {
  resetAutocomplete,
  clearResults,
  autocompleteRequest,
  autocompleteSuccess,
  autocompleteFailure
} = slice.actions;

// autocomlete thunk
export const fetchAutocomplete = query => async (dispatch, getState) => {
  if (!query || query.trim().length < 3) {
    dispatch(resetAutocomplete());
    return;
  }
  const id = Math.random();

  const params = new URLSearchParams();
  params.append("query", query);
  params.append("id", id);

  const apiUrlQuery = params
    .toString()
    .replace(/%20/g, "+") // replace all %-encoded spaces to the '+' character
    .replace(/%2C/g, ","); // replace all %-encoded ',' to the ',' character

  const apiUrl = `/autocomplete?${apiUrlQuery}`;

  dispatch(autocompleteRequest({ id }));
  try {
    console.log("Autocomplete temporarily removed", apiUrl);
    dispatch(autocompleteFailure());
    // const results = await makeRequest(apiUrl);
    // dispatch(autocompleteSuccess(results));
  } catch (err) {
    dispatch(autocompleteFailure());
    dispatch(addMessage({ message: err.toString(), type: "error" }));
  }
};

// selectors

export const selectResults = state => state.autocomplete.results;
export const selectIsRequestingResults = state =>
  state.autocomplete.isRequesting;

export default slice.reducer;

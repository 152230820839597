import React from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import { refreshToken } from "../../features/auth/authSlice";
import Messages from "../Messages/Messages";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Home from "../Home/Home";
import Search from "../Search/Search";

const Chrome = () => {
  // on initial app load, attempt to log in automatically
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(refreshToken());
  }, [dispatch]);

  return (
    <>
      <>
        <Messages />
        <Header />
        <Switch>
          <Route path="/search">
            <Search />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </>
    </>
  );
};

export default Chrome;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Popper from "@material-ui/core/Popper";
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  usePopupState,
  bindToggle,
  bindPopper
} from "material-ui-popup-state/hooks";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";

import {
  fetchConcept,
  setActiveConcept,
  selectInformationForActiveConcept,
  selectIsRequestingConcepts
} from "../../features/concepts/conceptsSlice";

const StyledPaper = withStyles(theme => ({
  root: {
    border: `8px solid ${theme.palette.grey.light}`,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.typography.text,
    padding: "16px",
    position: "relative",
    maxWidth: 400,
    maxHeight: 150,
    overflowY: "scroll"
  }
}))(Paper);

const StyledButton = withStyles(theme => ({
  root: {
    position: "absolute",
    minWidth: "auto",
    padding: "8px 16px",
    right: 0,
    top: 0
  }
}))(Button);

const useStyles = makeStyles(theme => ({
  loading: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(3)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.primary.dark
    }
  }
}));

const ChipWithPopper = props => {
  const { id, title } = props;
  const popupState = usePopupState({
    variant: "popper",
    popupId: id
  });

  const classes = useStyles();

  const dispatch = useDispatch();
  const isRequestingConcepts = useSelector(selectIsRequestingConcepts);
  const activeConcept = useSelector(selectInformationForActiveConcept);

  // Query the API when the popup is popped up
  React.useEffect(() => {
    if (popupState.isOpen) {
      dispatch(setActiveConcept(title));
      dispatch(fetchConcept());
    }
  }, [popupState.isOpen, title, dispatch]);

  return (
    <ClickAwayListener onClickAway={popupState.close}>
      <div>
        <Chip
          label={title}
          clickable
          color="primary"
          {...bindToggle(popupState)}
        />
        <Popper {...bindPopper(popupState)} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <StyledPaper>
                <StyledButton size="small" onClick={popupState.close}>
                  x
                </StyledButton>
                <Typography variant="h6" gutterBottom>
                  {title}
                </Typography>
                {isRequestingConcepts ? (
                  <div className={classes.loading}>
                    <CircularProgress />
                  </div>
                ) : activeConcept && activeConcept.summary ? (
                  <>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      gutterBottom
                    >
                      {activeConcept.summary}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <Link className={classes.link} href={activeConcept.url}>
                        Read more
                      </Link>
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                      Powered by Wikipedia
                    </Typography>
                  </>
                ) : (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Sorry, we couldn't find any further details about this.
                  </Typography>
                )}
              </StyledPaper>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default ChipWithPopper;

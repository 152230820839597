import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import { selectIsLoggedIn } from "../../features/auth/authSlice";
import {
  parseURLParamsAndQuery,
  resetSearch,
  selectIsRequestingResults
} from "../../features/search/searchSlice";
import { resetAutocomplete } from "../../features/autocomplete/autocompleteSlice";
import SearchBox from "../SearchBox/SearchBox";
import SearchResults from "../SearchResults/SearchResults";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: "flex",
    alignItems: "flex-start"
  },
  resultsLoading: {
    pointerEvents: "none"
  },
  resultsVisible: {
    pointerEvents: "auto"
  }
}));

const Search = props => {
  const { search } = useLocation();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(selectIsLoggedIn);

  // Update the state (and query the API) every time the URL `?params` change
  React.useEffect(() => {
    if (isLoggedIn) {
      dispatch(parseURLParamsAndQuery(search));
    }
  }, [dispatch, search, isLoggedIn]);

  // Reset search state on component unload
  React.useEffect(() => {
    return () => {
      dispatch(resetSearch());
      dispatch(resetAutocomplete());
    };
  }, [dispatch]);

  const classes = useStyles();

  const isLoadingResults = useSelector(selectIsRequestingResults);

  const resultsState = isLoadingResults
    ? classes.resultsLoading
    : classes.resultsVisible;

  return (
    <main className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box mt={6} mb={2}>
              <SearchBox />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {isLoggedIn ? (
              <Box className={resultsState} mb={6}>
                <SearchResults />
              </Box>
            ) : (
              <Typography
                align="center"
                variant="body2"
                color="textPrimary"
                gutterBottom
              >
                Please <Link href="/">log in</Link>
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default Search;

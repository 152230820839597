import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import {
  selectIsRequestingAuth,
  requestToken
} from "../../features/auth/authSlice";

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.primary.xlight,
    color: theme.palette.typography.text,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(3)
  }
}));

const LogIn = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const isRequestingAuth = useSelector(selectIsRequestingAuth);

  const [username, setUsername] = React.useState("");

  const handleUsernameChange = e => {
    setUsername(e.target.value);
  };

  const [password, setPassword] = React.useState("");

  const handlePasswordChange = e => {
    setPassword(e.target.value);
  };

  const handleSubmit = e => {
    if (e) e.preventDefault();

    if (username && password) {
      dispatch(requestToken({ username, password }));
    }
  };

  return (
    <Grid container justify="center" spacing={1}>
      <Grid item xs={12} sm={4}>
        <Paper className={classes.paper} elevation={0} square>
          <form onSubmit={handleSubmit}>
            <Typography paragraph variant="h6" align="center">
              Log in
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Username"
                  name="username"
                  variant="outlined"
                  value={username}
                  onChange={handleUsernameChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Password"
                  name="password"
                  type="password"
                  variant="outlined"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={isRequestingAuth}
                >
                  Log in
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default LogIn;

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import isValid from "date-fns/isValid";
import format from "date-fns/format";
import { useSelector, useDispatch } from "react-redux";

import {
  getDateOptions,
  setDataSource,
  setCategory,
  setStartDate,
  setEndDate,
  selectDataSource,
  selectCategory,
  selectStartDate,
  selectEndDate,
  resetStartEndDates,
  setDateFromOptions
} from "../../features/search/searchSlice";

// generate date options for date select dropdown
const dateOptions = getDateOptions();

const SearchFilters = props => {
  const StyledPaper = withStyles(theme => ({
    root: {
      backgroundColor: theme.palette.primary.xlight,
      color: theme.palette.typography.text
    }
  }))(Paper);

  const StyledTypography = withStyles(theme => ({
    root: {
      background: theme.palette.primary.light,
      color: theme.palette.typography.text,
      padding: theme.spacing(2)
    }
  }))(Typography);

  const StyledListItemIcon = withStyles(theme => ({
    root: {
      minWidth: "auto"
    }
  }))(ListItemIcon);

  const dispatch = useDispatch();

  // data source updates
  const dataSource = useSelector(selectDataSource);

  const handleDataSourceSelect = value => () => {
    let newDataSource;

    if (dataSource.length === 0) {
      newDataSource = props.dataSources.map(source => source.value);
    } else {
      newDataSource = dataSource.split(",");
    }
    const currentIndex = newDataSource.indexOf(value);

    if (currentIndex === -1) {
      newDataSource.push(value);
    } else {
      newDataSource.splice(currentIndex, 1);
    }

    dispatch(setDataSource(newDataSource.join(",")));
  };

  const [dataSourceOpen, setDataSourceOpen] = React.useState(false);
  React.useEffect(() => {
    setDataSourceOpen(dataSource.length > 0);
  }, [dataSource]);

  const handleDataSourceClick = () => {
    setDataSourceOpen(!dataSourceOpen);
  };

  // category updates
  const category = useSelector(selectCategory);

  const handleCategorySelect = value => () => {
    dispatch(setCategory(value));
  };

  const [categoriesOpen, setCategoriesOpen] = React.useState(false);
  React.useEffect(() => {
    setCategoriesOpen(true);
  }, [category]);

  const handleCategoriesClick = () => {
    setCategoriesOpen(!categoriesOpen);
  };

  // start date updates
  const startDate = useSelector(selectStartDate);
  const [formattedStartDate, setFormattedStartDate] = React.useState(false);

  React.useEffect(() => {
    const date = new Date(startDate);
    if (isValid(date)) {
      setFormattedStartDate(date);
      if (dateOptions.some(dateOption => dateOption.value === startDate)) {
        setSelectedDate(startDate);
        setShowDateInputs(false);
      } else {
        setSelectedDate("manual");
        setShowDateInputs(true);
      }
    } else {
      setFormattedStartDate(null);
      setSelectedDate("");
      setShowDateInputs(false);
    }
  }, [startDate]);

  const handleStartDateSelect = value => {
    if (isValid(value)) {
      dispatch(setStartDate(format(value, "yyyy-MM-dd")));
    } else if (value === null) {
      dispatch(setStartDate(""));
    }
  };

  // end date updates
  const endDate = useSelector(selectEndDate);
  const [formattedEndDate, setFormattedEndDate] = React.useState(false);

  React.useEffect(() => {
    const date = new Date(endDate);
    if (isValid(date)) {
      setFormattedEndDate(date);
      setSelectedDate("manual");
      setShowDateInputs(true);
    } else {
      setShowDateInputs(false);
      setFormattedEndDate(null);
    }
  }, [endDate]);

  const handleEndDateSelect = value => {
    if (isValid(value)) {
      dispatch(setEndDate(format(value, "yyyy-MM-dd")));
    } else if (value === null) {
      dispatch(setEndDate(""));
    }
  };

  const [dateOpen, setDateOpen] = React.useState(false);
  React.useEffect(() => {
    setDateOpen(startDate.length > 0 || endDate.length > 0);
  }, [startDate, endDate]);

  const handleDateClick = () => {
    setDateOpen(!dateOpen);
  };

  // date dropdown updates
  const [selectedDate, setSelectedDate] = React.useState(startDate);
  const [showDateInputs, setShowDateInputs] = React.useState(false);
  const handleDateSelect = event => {
    const { value } = event.target;
    const date = new Date(value);
    setSelectedDate(value);
    setShowDateInputs(value === "manual");
    if (value === "") {
      dispatch(resetStartEndDates());
    } else if (isValid(date)) {
      dispatch(setDateFromOptions(value));
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StyledPaper elevation={0} square color="primary">
        <List
          subheader={<StyledTypography variant="h6">FILTER</StyledTypography>}
          disablePadding
        >
          {props.categories && (
            <>
              <Divider />
              <ListItem button onClick={handleCategoriesClick}>
                <ListItemText
                  primary={<Typography variant="body2">CATEGORIES</Typography>}
                />
                {categoriesOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={categoriesOpen} timeout="auto" unmountOnExit>
                <List dense disablePadding>
                  {props.categories.map(({ value, label }) => (
                    <ListItem
                      key={value}
                      button
                      onClick={handleCategorySelect(value)}
                    >
                      <StyledListItemIcon>
                        <Radio
                          size="small"
                          checked={value === category}
                          tabIndex={-1}
                          disableRipple
                          color="primary"
                        />
                      </StyledListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2">{label}</Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </>
          )}
          <Divider />
          <ListItem button onClick={handleDataSourceClick}>
            <ListItemText
              primary={<Typography variant="body2">DATA SOURCES</Typography>}
            />
            {dataSourceOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={dataSourceOpen} timeout="auto" unmountOnExit>
            <List dense disablePadding>
              {props.dataSources.map(({ value, label }) => (
                <ListItem
                  key={value}
                  button
                  onClick={handleDataSourceSelect(value)}
                >
                  <StyledListItemIcon>
                    <Checkbox
                      size="small"
                      checked={
                        dataSource.length === 0 ||
                        dataSource.indexOf(value) !== -1
                      }
                      tabIndex={-1}
                      disableRipple
                      color="primary"
                    />
                  </StyledListItemIcon>
                  <ListItemText
                    primary={<Typography variant="body2">{label}</Typography>}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
          {props.dates && (
            <>
              <Divider />
              <ListItem button onClick={handleDateClick}>
                <ListItemText
                  primary={<Typography variant="body2">DATE</Typography>}
                />
                {dateOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={dateOpen} timeout="auto" unmountOnExit>
                <List dense disablePadding>
                  <ListItem>
                    <FormControl margin="normal" fullWidth>
                      <Select
                        labelId="date-select-label"
                        id="date-select"
                        displayEmpty
                        value={selectedDate}
                        variant="outlined"
                        onChange={handleDateSelect}
                      >
                        <MenuItem value="">
                          <em>All dates</em>
                        </MenuItem>
                        {dateOptions.map(dateOption => (
                          <MenuItem
                            key={dateOption.value}
                            value={dateOption.value}
                          >
                            {dateOption.label}
                          </MenuItem>
                        ))}
                        <MenuItem value="manual">Enter date range...</MenuItem>
                      </Select>
                    </FormControl>
                  </ListItem>
                  <Collapse in={showDateInputs} timeout="auto" unmountOnExit>
                    <ListItem>
                      <Typography variant="body2">Select dates</Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <KeyboardDatePicker
                            label="Start date"
                            inputVariant="outlined"
                            value={formattedStartDate}
                            maxDate={formattedEndDate}
                            maxDateMessage="Start date cannot be after end date"
                            placeholder="DD-MM-YYYY"
                            format="dd-MM-yyyy"
                            clearable
                            disableFuture
                            onChange={handleStartDateSelect}
                          />
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <KeyboardDatePicker
                            label="End date"
                            inputVariant="outlined"
                            value={formattedEndDate}
                            minDate={formattedStartDate}
                            minDateMessage="End date cannot be before start date"
                            placeholder="DD-MM-YYYY"
                            format="dd-MM-yyyy"
                            clearable
                            disableFuture
                            onChange={handleEndDateSelect}
                          />
                        }
                      />
                    </ListItem>
                  </Collapse>
                </List>
              </Collapse>
            </>
          )}
        </List>
      </StyledPaper>
    </MuiPickersUtilsProvider>
  );
};

export default SearchFilters;

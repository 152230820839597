const apiUrlBase = process.env.REACT_APP_API_BASE_URL;

export let headers = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

// allow headers to be modified
export function setHeaders(newHeaders) {
  headers = { ...headers, ...newHeaders };
}

export function removeHeader(header) {
  if (headers[header]) {
    delete headers[header];
  }
}

function RequestException({ error, response, data }) {
  // friendly error messages for production,
  // useful error messages during development
  const isDebugMode = process.env.NODE_ENV === "development";
  const productionErrorMessage =
    "Sorry, we encountered an error with your request, please try again";

  if (isDebugMode) {
    console.log("ERROR", error);
    console.log("RESPONSE", response);
    console.log("DATA", data);
  }

  const message = isDebugMode
    ? data.detail || response.message || response.statusText
    : productionErrorMessage;

  throw new Error(message);
}

export async function makeRequest(apiUrl, options = {}) {
  // add headers to all requests
  options.headers = { ...options.headers, ...headers };

  let response, data;

  try {
    response = await fetch(apiUrlBase + apiUrl, options);
    data = await response.json();
  } catch (error) {
    throw new RequestException({ error, response, data });
  }

  if (response.ok) {
    return data;
  } else {
    throw new RequestException({ response, data });
  }
}

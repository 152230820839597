import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import SearchControls from "../SearchControls/SearchControls";
import SearchResultsListGoogleSearch from "../SearchResultsListGoogleSearch/SearchResultsListGoogleSearch";
import SearchResultsPagination from "../SearchResultsPagination/SearchResultsPagination";

const SearchResultsPublicSources = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box mt={2}>
            <SearchControls />
          </Box>
          <Box mt={2}>
            <SearchResultsListGoogleSearch />
          </Box>
          <Box mt={5} mb={2}>
            <SearchResultsPagination />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchResultsPublicSources;

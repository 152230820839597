import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const initialState = {
  query: "",
  connections: []
};

export const slice = createSlice({
  name: "connections",
  initialState,
  reducers: {
    resetConnections: state => initialState,
    addConnections: (state, action) => {
      const { query, results } = action.payload;
      state.connections = results;
      state.query = query;
    }
  }
});

// expose actions

export const { resetConnections, addConnections } = slice.actions;

// selectors

export const selectQuery = state => state.connections.query;
export const selectConnections = state => state.connections.connections;
export const selectFormattedConnectionData = createSelector(
  selectQuery,
  selectConnections,
  (query, connections) => {
    return {
      node: "query",
      concept: query,
      children: connections.map(connection => ({
        node: "result",
        ...connection
      }))
    };
  }
);

export default slice.reducer;

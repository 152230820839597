import React from "react";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";

const EmptyResults = () => {
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    setShow(true);
    return () => {
      setShow(false);
    };
  }, []);
  return (
    <Collapse in={show} timeout={1000}>
      <Typography variant="body1" color="textSecondary">
        We couldn't find any results, please try another search.
      </Typography>
    </Collapse>
  );
};

export default EmptyResults;

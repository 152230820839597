import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import SearchFilters from "../SearchFilters/SearchFilters";
import SearchControls from "../SearchControls/SearchControls";
import SearchResultsListNewsArticles from "../SearchResultsListNewsArticles/SearchResultsListNewsArticles";
import SearchResultsPagination from "../SearchResultsPagination/SearchResultsPagination";
import { newsArticlesDataSources } from "../../features/search/searchSlice";

const SearchResultsNewsArticles = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Box mt={2}>
            <SearchFilters dataSources={newsArticlesDataSources} dates />
          </Box>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Box mt={2}>
            <SearchControls />
          </Box>
          <Box mt={2}>
            <SearchResultsListNewsArticles />
          </Box>
          <Box mt={5} mb={2}>
            <SearchResultsPagination />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchResultsNewsArticles;

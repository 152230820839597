import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import LaunchIcon from "@material-ui/icons/Launch";

const footerLinks = [
  {
    title: "Blog",
    url: "https://blogs.city.ac.uk/dminr/",
    external: true
  }
];

const StyledAppBar = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.typography.white
  }
}))(AppBar);

const StyledToolbar = withStyles(theme => ({
  root: {
    flexWrap: "wrap"
  }
}))(Toolbar);

const StyledTypography = withStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  }
}))(Typography);

const useStyles = makeStyles(theme => ({
  middle: {
    flexGrow: 1
  },
  link: {
    color: theme.palette.typography.white,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.grey.xlight
    }
  }
}));

const Footer = () => {
  const classes = useStyles();
  const today = new Date();
  const year = today.getFullYear();
  return (
    <StyledAppBar position="static" component="footer">
      <StyledToolbar>
        {footerLinks.map((footerLink, index) => (
          <React.Fragment key={footerLink.title}>
            <StyledTypography variant="body2">
              {footerLink.external ? (
                <Link className={classes.link} href={footerLink.url}>
                  {footerLink.title} <LaunchIcon style={{ fontSize: 10 }} />
                </Link>
              ) : (
                <Link
                  className={classes.link}
                  component={RouterLink}
                  to={footerLink.url}
                >
                  {footerLink.title}
                </Link>
              )}
            </StyledTypography>
            {index < footerLinks.length - 1 && " • "}
          </React.Fragment>
        ))}
        <div className={classes.middle} />
        <Typography variant="body2">
          &copy; {year} DMINR – All Rights Reserved.
        </Typography>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default Footer;

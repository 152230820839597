import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import { setQuery, selectQuery } from "../../features/search/searchSlice";
import {
  selectResults,
  selectIsRequestingResults,
  fetchAutocomplete
} from "../../features/autocomplete/autocompleteSlice";

const StyledButton = withStyles(theme => ({
  root: {
    width: "100%"
  }
}))(Button);

const SearchBox = () => {
  const dispatch = useDispatch();

  const [showAutocomplete, setShowAutocomplete] = React.useState(false);

  // inline state for storing the search form value prior to form submission
  const query = useSelector(selectQuery);
  const [searchValue, setSearchValue] = React.useState(query);

  const handleTextChange = e => {
    setSearchValue(e.target.value);
  };

  // we need to trigger the search manually and immediately, so we can't
  // wait for the search value be stored in state before handling the search
  const handleAutocompleteValueSelect = (e, value) => {
    setSearchValue(value);
    if (value) {
      handleSearch(value);
    }
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      handleSearch();
      setShowAutocomplete(false);
    }
  };

  const handleSubmit = e => {
    handleSearch();
  };

  // method to call when search is triggered, to make search request
  // value may be passed through directly, if it comes via autocomplete
  // otherwise retrieve from state
  const handleSearch = latestValue => {
    const value = latestValue || searchValue;
    if (value && value.trim().length > 0) {
      dispatch(setQuery(value.trim()));
    }
  };

  // update search value on load
  React.useEffect(() => {
    setSearchValue(query);
  }, [query]);

  const results = useSelector(selectResults);
  const isRequestingResults = useSelector(selectIsRequestingResults);

  React.useEffect(() => {
    dispatch(fetchAutocomplete(searchValue));
  }, [dispatch, searchValue]);

  return (
    <Grid container justify="center" spacing={1}>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          freeSolo
          size="small"
          onChange={handleAutocompleteValueSelect}
          value={searchValue}
          options={results}
          open={showAutocomplete}
          onOpen={() => {
            setShowAutocomplete(true);
          }}
          onClose={() => {
            setShowAutocomplete(false);
          }}
          renderInput={params => (
            <TextField
              type="text"
              label="Search"
              color="primary"
              variant="outlined"
              onKeyPress={handleKeyPress}
              onChange={handleTextChange}
              fullWidth
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isRequestingResults ? (
                      <CircularProgress size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
          renderOption={(option, { inputValue }) => {
            const matches = match(option, inputValue);
            const parts = parse(option, matches);
            return (
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            );
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <StyledButton
          variant="contained"
          color="primary"
          size="medium"
          startIcon={<SearchIcon />}
          onClick={handleSubmit}
        >
          Search
        </StyledButton>
      </Grid>
    </Grid>
  );
};

export default SearchBox;

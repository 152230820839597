import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { useSelector, useDispatch } from "react-redux";

import {
  setSort,
  selectSort,
  selectPage,
  selectResults
} from "../../features/search/searchSlice";

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.primary.dark
    }
  },
  selectedLink: {
    color: theme.palette.grey.main,
    textDecoration: "none",
    pointerEvents: "none"
  }
}));

const SearchControls = props => {
  const dispatch = useDispatch();
  const sort = useSelector(selectSort);
  const page = useSelector(selectPage);
  const results = useSelector(selectResults);

  const handleRelevanceSortClick = () => {
    dispatch(setSort("relevance"));
  };

  const handleDateSortClick = () => {
    dispatch(setSort("date"));
  };

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3} justify="space-between" alignItems="center">
        <Grid item xs={4} md={3}>
          <Typography variant="body2">
            {results && results.pagination && (
              <>
                {page > 1 && <>Page {page} of </>}
                {results.pagination.results_formatted
                  ? results.pagination.results_formatted
                  : results.pagination.results}{" "}
                results
              </>
            )}
          </Typography>
        </Grid>
        {props.sortable && (
          <Grid align="right" item xs={8} md={3}>
            <Typography variant="body2">
              Sort by:{" "}
              <Link
                component="button"
                className={
                  sort === "date" ? classes.link : classes.selectedLink
                }
                variant="body2"
                onClick={handleRelevanceSortClick}
              >
                Relevance
              </Link>
              {" / "}
              <Link
                component="button"
                className={
                  sort !== "date" ? classes.link : classes.selectedLink
                }
                variant="body2"
                onClick={handleDateSortClick}
              >
                Date
              </Link>
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SearchControls;

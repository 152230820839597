import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import EmptyResults from "../EmptyResults/EmptyResults";
import ResultsTable from "../ResultsTable/ResultsTable";
import {
  selectResults,
  selectIsRequestingResults
} from "../../features/search/searchSlice";

const useStyles = makeStyles(theme => ({
  loading: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(3)
  },
  mainLink: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.primary.dark
    }
  },
  urlLink: {
    color: "inherit",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.primary.main
    }
  }
}));

const cells = [
  { id: "officer_name", label: "Officer Name", url: "url" },
  { id: "description", label: "Description" },
  { id: "company", label: "Company" },
  { id: "source", label: "Source" }
];

const SearchResultsListPublicSourcesOfficers = () => {
  const results = useSelector(selectResults);
  const isLoadingResults = useSelector(selectIsRequestingResults);

  const classes = useStyles();

  return (
    <>
      {isLoadingResults && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
      {!isLoadingResults && (!results || !results.results) && <EmptyResults />}
      {results && results.results && (
        <ResultsTable cells={cells} rows={results.results} paginate={false} />
      )}
    </>
  );
};

export default SearchResultsListPublicSourcesOfficers;

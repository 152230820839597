import { createMuiTheme } from "@material-ui/core/styles";
import { red, blue, blueGrey } from "@material-ui/core/colors";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: blue[500],
      dark: blue[900],
      light: blue[100],
      xlight: blue[50]
    },
    secondary: {
      main: red[500],
      dark: red[900],
      light: red[300],
      xlight: red[100]
    },
    grey: {
      xlight: blueGrey[50],
      light: blueGrey[100],
      main: blueGrey[500],
      dark: blueGrey[900]
    },
    entities: {
      thing: "#9BF272",
      person: "#732C02",
      place: "#04C4D9",
      organisation: "#F2A922"
    },
    error: {
      main: red.A400
    },
    action: {
      hover: blue[50]
    },
    background: {
      default: "#fff"
    },
    typography: {
      default: blueGrey[900],
      light: "#fff",
      white: "#fff"
    }
  }
});

export default theme;

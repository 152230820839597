import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";

import SearchFilters from "../SearchFilters/SearchFilters";
import SearchControls from "../SearchControls/SearchControls";
import SearchResultsListPublicSourcesCompanies from "../SearchResultsListPublicSourcesCompanies/SearchResultsListPublicSourcesCompanies";
import SearchResultsListPublicSourcesOfficers from "../SearchResultsListPublicSourcesOfficers/SearchResultsListPublicSourcesOfficers";
import SearchResultsPagination from "../SearchResultsPagination/SearchResultsPagination";
import {
  selectCategory,
  publicSourcesDataSources,
  publicSourcesCategories
} from "../../features/search/searchSlice";

const SearchResultsPublicSources = () => {
  // data source updates
  const category = useSelector(selectCategory);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Box mt={2}>
            <SearchFilters
              dataSources={publicSourcesDataSources}
              categories={publicSourcesCategories}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Box mt={2}>
            <SearchControls />
          </Box>
          <Box mt={2}>
            {category === "companies" && (
              <SearchResultsListPublicSourcesCompanies />
            )}
            {category === "officers" && (
              <SearchResultsListPublicSourcesOfficers />
            )}
          </Box>
          <Box mt={5} mb={2}>
            <SearchResultsPagination />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchResultsPublicSources;

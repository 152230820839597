import React from "react";
import "typeface-roboto";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { CookiesProvider } from "react-cookie";

import theme from "../../theme";
import store from "../../features/store";
import history from "../../features/history";
import Chrome from "../Chrome/Chrome";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import "./App.css";

const App = () => {
  return (
    <Provider store={store}>
      <CookiesProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router history={history}>
            <ScrollToTop />
            <Chrome />
          </Router>
        </ThemeProvider>
      </CookiesProvider>
    </Provider>
  );
};

export default App;
